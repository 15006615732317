<template>
  <div class="mr-3 w-full">
    <div v-if="sites && sites.length == 0 && !loading">
      {{ $t("top.no_sites") }}
    </div>
    <div class="w-9/12 pt-5" v-if="loading">
      <div
        class="
          border border-gray-200
          dark:border-gray-700
          bg-white
          dark:bg-gray-800
          rounded-md
          p-4
          w-full
          mx-auto
          mt-5
        "
        v-for="i in 3"
        :key="i"
      >
        <div class="animate-pulse flex space-x-4">
          <div class="rounded bg-gray-200 h-20 w-1/5"></div>
          <div class="flex-1 space-y-4 py-1">
            <div class="flex items-center">
              <div
                class="rounded-full inline-block bg-gray-200 h-10 w-10 mr-5"
              ></div>
              <div class="h-4 inline-block bg-gray-200 rounded w-1/4"></div>
            </div>
            <div class="space-y-2">
              <div class="h-4 bg-gray-200 rounded w-3/4"></div>
              <div class="h-4 bg-gray-200 rounded"></div>
              <div class="h-4 bg-gray-200 rounded w-5/6"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!loading && sites.length > 0">
      <div
        class="p-5 border dark:border-gray-700 dark:bg-gray-800 rounded my-3"
        v-for="(site, index) in sites"
        :key="index"
      >
        <div class="md:flex">
          <div class="md:w-4/5">
            <div class="site-container">
              <router-link
                :to="
                  $i18nRoute({
                    name: 'site',
                    params: { domain: site.domain },
                  })
                "
              >
                <div
                  v-if="site.icon"
                  class="inline-block w-10 align-middle mr-2"
                >
                  <img
                    :src="root + site.icon"
                    class="max-w-full max-h-full rounded"
                  />
                </div>
                {{ site.domain }}
              </router-link>
            </div>
            <div class="flex items-center mt-4">
              <RatingTag rating="AR" :value="site.advisor_rank" />
              <RatingTag rating="WR" :value="site.webmoney_rank" />
              <RatingTag rating="ALR" :value="site.alexa_rank" />
              <RatingTag
                rating="UR"
                :value="site.average_feedbacks_point"
                decimal
              />
              <div class="inline-block">
                <router-link
                  :to="
                    $i18nRoute({
                      name: 'feedbacks',
                      params: { domain: site.domain },
                    })
                  "
                  nohref="true"
                  class="cursor-pointer text-sm flex items-center"
                >
                  <message-circle-icon
                    size="1x"
                    class="mr-1 inline-block"
                  ></message-circle-icon>
                  {{ $tc("shared.feedbacks_count", site.feedbacks_count) }}
                </router-link>
              </div>
            </div>

            <div
              class="mt-3 text-gray-500 text-sm dark:text-white"
              v-if="site.description"
            >
              {{ site.description | limitText }}
            </div>
          </div>
          <div
            class="hidden md:block w-1/5 pl-5 relative"
            v-if="site.screenshot"
          >
            <div v-if="site.screenshot">
              <router-link
                :to="
                  $i18nRoute({
                    name: 'site',
                    params: { domain: site.domain },
                  })
                "
              >
                <img
                  :src="root + site.screenshot"
                  class="rounded"
                  :class="{ 'filter blur-sm': site.explicit }"
                />
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { MessageCircleIcon } from "vue-feather-icons";
import RatingTag from "@/components/RatingTag.vue";
export default {
  components: {
    RatingTag,
    MessageCircleIcon,
  },
  props: ["sites", "loading"],
  data() {
    return {
      root: process.env.VUE_APP_BACKEND_URL,
    };
  },
};
</script>

<style lang="scss" scoped>
</style>
