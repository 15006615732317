<template>
  <div class="container w-full mx-auto py-10 px-4 md:px-0">
    <div class="flex">
      <button
        @click="$router.go(-1)"
        v-if="category"
        class="self-center text-webmoney rounded mr-4 mb-4 hover:text-blue-400"
      >
        <ArrowLeftIcon size="2x" />
      </button>
      <h1
        class="
          font-bold
          uppercase
          text-2xl
          tracking-tight
          text-webmoney
          dark:text-blue-400
          mb-4
        "
      >
        {{
          category
            ? subCategoryTitle["title_" + $i18n.locale]
            : $t("catalog.title")
        }}
      </h1>
    </div>

    <p class="text-xl text-gray-500 dark:text-gray-400 mb-5">
      {{ category ? $t("catalog.category_subtitle") : $t("catalog.subtitle") }}
    </p>

    <Categories />

    <div class="w-full flex flex-col" v-if="category">
      <SiteItems :sites="sitesList" :loading="loading" />
      <button
        v-if="sitesList.length === page * 10 && !loading"
        type="button"
        @click="nextPage"
        class="
          ml-auto
          inline-block
          bg-webmoney
          text-white
          px-4
          py-1
          rounded
          hover:bg-blue-400 hover:text-white
          shadow
        "
      >
        {{ $t("top.more_sites") }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Categories from "@/components/catalog/Categories.vue";
import SiteItems from "@/components/SiteItems.vue";
import { ArrowLeftIcon } from "vue-feather-icons";

export default {
  components: {
    Categories,
    SiteItems,
    ArrowLeftIcon,
  },
  computed: mapState({
    sitesList: (state) => state.catalog.sitesList,
    categoriesList: (state) => state.catalog.categoriesList,
    page: (state) => state.catalog.page,
    loading: (state) => state.catalog.loading,
    category: (state) => state.catalog.category,
    subCategoryTitle: (state) => state.catalog.subCategoryTitle,
  }),
  mounted() {
    if (this.$route.params.category || this.$route.params.subCategory) {
      this.$route.params.subCategory
        ? this.getSubCategory(
            this.$route.params.category,
            this.$route.params.subCategory
          )
        : this.getCategories(this.$route.params.category);
    } else {
      this.getDefaultCategories();
    }
  },
  methods: {
    getDefaultCategories() {
      this.$store.dispatch("catalog/getDefaultCategories");
    },
    getCategories(slug) {
      this.$store.dispatch("catalog/getCategoryIdBySlug", slug);
    },
    getSubCategory(category, subCategory) {
      this.$store.dispatch("catalog/getSubCategoryIdBySlug", {
        category,
        subCategory,
      });
    },
    nextPage() {
      this.$store.dispatch("catalog/loadSites", this.page + 1);
    },
  },
  watch: {
    $route: [
      function () {
        if (this.$route.params.category || this.$route.params.subCategory) {
          this.$route.params.subCategory
            ? this.getSubCategory(
                this.$route.params.category,
                this.$route.params.subCategory
              )
            : this.getCategories(this.$route.params.category);
        } else {
          this.getDefaultCategories();
        }
      },
    ],
  },
};
</script>

<style lang="scss" scoped>
</style>
