var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mr-3 w-full"},[(_vm.sites && _vm.sites.length == 0 && !_vm.loading)?_c('div',[_vm._v(" "+_vm._s(_vm.$t("top.no_sites"))+" ")]):_vm._e(),(_vm.loading)?_c('div',{staticClass:"w-9/12 pt-5"},_vm._l((3),function(i){return _c('div',{key:i,staticClass:"\n        border border-gray-200\n        dark:border-gray-700\n        bg-white\n        dark:bg-gray-800\n        rounded-md\n        p-4\n        w-full\n        mx-auto\n        mt-5\n      "},[_vm._m(0,true)])}),0):_vm._e(),(!_vm.loading && _vm.sites.length > 0)?_c('div',_vm._l((_vm.sites),function(site,index){return _c('div',{key:index,staticClass:"p-5 border dark:border-gray-700 dark:bg-gray-800 rounded my-3"},[_c('div',{staticClass:"md:flex"},[_c('div',{staticClass:"md:w-4/5"},[_c('div',{staticClass:"site-container"},[_c('router-link',{attrs:{"to":_vm.$i18nRoute({
                  name: 'site',
                  params: { domain: site.domain },
                })}},[(site.icon)?_c('div',{staticClass:"inline-block w-10 align-middle mr-2"},[_c('img',{staticClass:"max-w-full max-h-full rounded",attrs:{"src":_vm.root + site.icon}})]):_vm._e(),_vm._v(" "+_vm._s(site.domain)+" ")])],1),_c('div',{staticClass:"flex items-center mt-4"},[_c('RatingTag',{attrs:{"rating":"AR","value":site.advisor_rank}}),_c('RatingTag',{attrs:{"rating":"WR","value":site.webmoney_rank}}),_c('RatingTag',{attrs:{"rating":"ALR","value":site.alexa_rank}}),_c('RatingTag',{attrs:{"rating":"UR","value":site.average_feedbacks_point,"decimal":""}}),_c('div',{staticClass:"inline-block"},[_c('router-link',{staticClass:"cursor-pointer text-sm flex items-center",attrs:{"to":_vm.$i18nRoute({
                    name: 'feedbacks',
                    params: { domain: site.domain },
                  }),"nohref":"true"}},[_c('message-circle-icon',{staticClass:"mr-1 inline-block",attrs:{"size":"1x"}}),_vm._v(" "+_vm._s(_vm.$tc("shared.feedbacks_count", site.feedbacks_count))+" ")],1)],1)],1),(site.description)?_c('div',{staticClass:"mt-3 text-gray-500 text-sm dark:text-white"},[_vm._v(" "+_vm._s(_vm._f("limitText")(site.description))+" ")]):_vm._e()]),(site.screenshot)?_c('div',{staticClass:"hidden md:block w-1/5 pl-5 relative"},[(site.screenshot)?_c('div',[_c('router-link',{attrs:{"to":_vm.$i18nRoute({
                  name: 'site',
                  params: { domain: site.domain },
                })}},[_c('img',{staticClass:"rounded",class:{ 'filter blur-sm': site.explicit },attrs:{"src":_vm.root + site.screenshot}})])],1):_vm._e()]):_vm._e()])])}),0):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"animate-pulse flex space-x-4"},[_c('div',{staticClass:"rounded bg-gray-200 h-20 w-1/5"}),_c('div',{staticClass:"flex-1 space-y-4 py-1"},[_c('div',{staticClass:"flex items-center"},[_c('div',{staticClass:"rounded-full inline-block bg-gray-200 h-10 w-10 mr-5"}),_c('div',{staticClass:"h-4 inline-block bg-gray-200 rounded w-1/4"})]),_c('div',{staticClass:"space-y-2"},[_c('div',{staticClass:"h-4 bg-gray-200 rounded w-3/4"}),_c('div',{staticClass:"h-4 bg-gray-200 rounded"}),_c('div',{staticClass:"h-4 bg-gray-200 rounded w-5/6"})])])])}]

export { render, staticRenderFns }