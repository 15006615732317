<template>
  <div
    class="inline-block border border-gray-700 overflow-hidden rounded mr-3 text-xs"
  >
    <div class="flex items-center">
      <span class="bg-gray-500 dark:bg-gray-700 text-white px-2 py-1 text-center">
        <span class="" v-if="!hover">
          {{ rating }}
        </span>
      </span>
      <span
        class="flex bg-white dark:bg-gray-600 dark:text-white text-gray-500 px-2 py-1 font-bold items-center"
      >
        <span>
          {{ value && decimal ? value.toFixed(2) : value || "—" }}
        </span>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      hover: false,
    };
  },
  components: {},
  props: {
    rating: {
      type: String,
      default: null,
    },
    value: {
      type: Number,
      //   default: "—",
    },
    decimal: Boolean,
  },
};
</script>

