<template>
  <div>
    <div
      class="
        flex flex-wrap
        w-full
        justify-center
        lg:-ml-4 lg:justify-start
        gap-5
      "
      v-if="!category"
    >
      <CategoriesItem
        v-for="(item, index) in categoriesList"
        :item="item"
        :key="index"
      />
    </div>
    <div class="flex flex-wrap w-full justify-start gap-5" v-else>
      <SubCategoriesItem
        v-for="(item, index) in categoriesList"
        :item="item"
        :key="index"
      />
    </div>
  </div>
</template>

<script>
import CategoriesItem from "@/components/catalog/CategoriesItem.vue";
import SubCategoriesItem from "@/components/catalog/SubCategoriesItem.vue";
import { mapState } from "vuex";
export default {
  components: {
    CategoriesItem,
    SubCategoriesItem,
  },
  computed: mapState({
    category: (state) => state.catalog.category,
    categoriesList: (state) => state.catalog.categoriesList,
  }),
};
</script>

<style lang="scss" scoped>
</style> 