<template>
    <router-link
        :to="{
            name: 'catalog',
            params: {
                category: item.slug,
            },
        }"
        class="
            flex flex-col
            w-60
            h-60
            m-4
            p-4
            hover:shadow-2xl
            rounded-xl
            justify-between
            group
            dark:hover:bg-gray-800
        "
    >
        <img
            v-if="item.is_root"
            :src="root + item.icon"
            alt="catalog_icon"
            class="w-12 h-12 mb-4"
        />
        <h3 class="text-xl font-medium mb-auto">
            {{ $i18n.locale == "ru" ? item.title : item.title_en }}
        </h3>
        <p v-if="item.description" class="text-sm text-gray-400">
            {{ $i18n.locale == "ru" ? item.description : item.description_en }}
        </p>
        <ArrowRightIcon
            class="w-10 h-10 text-webmoney group-hover:text-opacity-50 ml-auto"
        />
    </router-link>
</template>

<script>
import { ArrowRightIcon } from "vue-feather-icons";
import { mapState } from "vuex";

export default {
    components: {
        ArrowRightIcon,
    },
    props: ["item"],
    computed: mapState({
        category: (state) => state.catalog.category,
    }),
    data() {
        return {
            root: process.env.VUE_APP_BACKEND_URL,
        };
    },
};
</script>

<style lang="scss" scoped>
</style>