<template>
    <router-link
        :to="{
            name: 'catalog',
            params: {
                category: category,
                subCategory: item.slug,
            },
        }"
        class="
            border
            dark:border-gray-700
            rounded
            px-3
            py-2
            hover:bg-gray-100
            dark:hover:bg-gray-800
        "
    >
        <h3>
            {{ $i18n.locale == "ru" ? item.title : item.title_en }}
        </h3>
    </router-link>
</template>

<script>
import { mapState } from "vuex";

export default {
    props: ["item"],
    computed: mapState({
        category: (state) => state.catalog.category,
    }),
};
</script>

<style lang="scss" scoped>
</style>